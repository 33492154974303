import React from "react";
import "../styles/layout.css";
import NavBar from "./navbar";
import Footer from "./footer";
import Seo from "./seo";

const layout = ({ title, description, image, children }) => {
  return (
    <div className="layout-container">
      <Seo title={title} description={description} image={image} />
      <NavBar />
      <div style={{ margin: 0, padding: 0 }}>{children}</div>
      <Footer />
    </div>
  );
};

export default layout;

import React, { useState } from "react";
import Recaptcha from "react-recaptcha";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import emailjs from "emailjs-com";
import "../styles/footer.css";

const Footer = () => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [verify, setRecaptcha] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const recaptchaLoaded = () => {
    console.log("recaptcha has successfully loaded");
  };

  const verifyCallback = (response) => {
    if (response) {
      setRecaptcha(true);
    }
  };

  function valid() {
    if (
      name.length < 3 ||
      message < 3 ||
      !regex.test(String(email).toLowerCase()) ||
      !verify
    ) {
      return false;
    }
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4c2v0at",
        "template_t69urg9",
        e.target,
        "user_j49jOSpAOa5qxWGOuuFlm"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Thank you for your email. I will be in touch shortly!");
          setName("");
          setEmail("");
          setMessage("");
          setDisabled(true);
        },
        (error) => {
          alert(error.text);
        }
      );
  };

  return (
    <div className="footer-container">
      <div className="contact-container">
        <form onSubmit={handleSubmit} className="footer-form">
          <h1>Contact</h1>
          <CssTextField
            label="Name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <CssTextField
            label="Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <CssTextField
            label="Message"
            name="message"
            value={message}
            multiline
            rows={3}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Recaptcha
            sitekey="6Lc1oqscAAAAALnlZkFZ9y7gRrCJE0S4QnvwTJwD"
            render="explicit"
            onloadCallback={recaptchaLoaded}
            verifyCallback={verifyCallback}
          />
          <CssButton
            variant="contained"
            type="submit"
            disabled={disabled || !valid()}
          >
            Submit
          </CssButton>
        </form>
      </div>
    </div>
  );
};

const CssTextField = styled(TextField)({
  borderRadius: 4,
  color: "white",
  marginBottom: 15,
  borderColor: "white",
  width: "25%",
  "& label": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: "whitesmoke",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  ["@media (max-width:960px)"]: {
    width: "100%",
  },
});

const CssButton = styled(Button)({
  backgroundColor: "whitesmoke",
  marginTop: 20,
  color: "#315368",
  "&:hover": {
    backgroundColor: "white",
  },
  width: 100,
  ["@media (max-width:960px)"]: {
    width: "50%",
  },
});

export default Footer;

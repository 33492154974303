export const links = [
  {
    value: "Home",
    path: "/",
  },
  {
    value: "About",
    path: "/about",
  },
  {
    value: "Blog",
    path: "/blog",
  },
  {
    value: "News",
    path: "/news",
  },
  {
    value: "Videos",
    path: "/videos",
  },
];

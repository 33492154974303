import React, { useState } from "react";
import "../styles/navbar.css";
import { Link } from "gatsby";
import { links } from "../constants/nav-links";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <div className="nav-container">
      <div className="nav-header">
        <Link to="/" className="link">
          <h1>JOELI TOSCANO</h1>
        </Link>
      </div>
      <div className="nav-links">
        {links.map((link) => (
          <div key={link.value}>
            <Link className="link" to={link.path}>
              {link.value}
            </Link>
          </div>
        ))}
      </div>
      <div className="nav-links-mobile">
        {click ? (
          <CloseIcon sx={{ fontSize: 40 }} onClick={handleClick} />
        ) : (
          <Menu sx={{ fontSize: 40 }} onClick={handleClick} />
        )}
        <Drawer anchor="left" open={click} onClose={handleClick}>
          <List>
            {links.map((link) => (
              <ListItem key={link.value}>
                <ListItemText
                  primary={
                    <Link className="link-mobile" to={link.path}>
                      {link.value}
                    </Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>
    </div>
  );
};

export default Navbar;
